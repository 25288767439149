
import { ScrollView, HStack, VStack } from "@gluestack-ui/themed"
import { Button, Icon, IconButton, Pressable, View, Spacer, Spinner, Image, Text } from 'native-base';
import React, { FunctionComponent } from 'react';
import { MaterialIcons, Ionicons, FontAwesome5, MaterialCommunityIcons } from "@expo/vector-icons";
import { onPatch } from 'mobx-state-tree';
import * as DocumentPicker from 'expo-document-picker';
import * as FileSystem from "expo-file-system"
import { ElementBase, IElementBaseProps, ModalFull, useInitializeElement } from '..';
import { createDynamicParms, getDisplayAndValue, getItemHeader, getItemTitle } from '../../utils/itemCommonFunctions';
import { conditionIsGo } from '../../utils/conditionIsGo';
import OpenFile from 'react-native-files-viewer';
import { FieldsList } from "../FieldsList";
import { Dimensions } from "react-native";
import { useDebouncedCallback } from "use-debounce";
import { format } from "date-fns";

export const SimpleListElement: FunctionComponent<IElementBaseProps> = ({ ...props }) => {
    const { itemsStore, statusStore, integrationsStore, element, payload, setPayload, checkAndSetPayload, errorList, setErrorList, modalVisible, setModalVisible,
        loading, setLoading } = useInitializeElement(props, false, /* parseValueFromPopulate */);
    const [isValidActiveRecord, setIsValidActiveRecord] = React.useState<boolean>(false);
    const [listRecords, setListRecords] = React.useState(<View />);
    const cancelRecord = () => {
        setPayload({ ...payload, activeRecord: { idx: undefined, record: undefined } });
    }


    //para mantener compatibilidad con funcionamiento anterior
    if (element.integration)
        element.integration.userMode = element.integration.userMode == undefined ? true : element.integration.userMode;

    const walkNodes = (nodes, record) => {
        nodes.forEach((f: any) => {
            let nodeValid = itemsStore.getErrorElement(f, null);
            record[f.id] = { value: null, valid: f.isArchived || nodeValid.valid, type: f.type, templateData: { useAsTitle: f.templateData?.useAsTitle } }
            if (Array.isArray(f.nodes))
                walkNodes(f.nodes, record);
        });
    }

    const addRecord = () => {
        let record: any = {}
        walkNodes(element.nodes, record);
        setPayload({ ...payload, activeRecord: { idx: -1, record } });
    }

    const openRecord = (idx) => {
        function walk(nodes) {
            nodes.forEach((f: any) => {
                let nodeValid = itemsStore.getErrorElement(f, record[f.id]?.value);
                record2[f.id] = { value: record[f.id]?.value, valid: f.isArchived || nodeValid?.valid, type: f.type, templateData: { useAsTitle: f.templateData?.useAsTitle } }
                if (Array.isArray(f.nodes)) walk(f.nodes);
            });
        }
        let record: any = idx == -1 ? {} : { ...payload.value[idx] };
        let record2: any = {};
        walk(element.nodes);
        setPayload({ ...payload, activeRecord: { idx, record: record2 } });
    }

    const removeRecord = (idx) => {
        let records: any = payload.value.slice(0);
        records.splice(idx, 1);
        checkAndSetPayload(records);
    }

    const saveRecord = () => {
        console.log("saveRecord")
        let records: any[] = [].concat((payload.value || []));
        let activeRecord = itemsStore.getPayloadElement(element.id, props.parentId);
        if (activeRecord?.activeRecord?.idx == -1) {
            records.push(activeRecord.activeRecord.record);
        } else {
            records[activeRecord.activeRecord.idx] = activeRecord.activeRecord.record;
        }
        checkAndSetPayload(records, { idx: undefined, record: undefined })

    }

    const processCSV = async (content) => {
        let records: any[] = [].concat(payload.value || []);
        let mustUpdate: boolean = false;
        content.split("\n").forEach((row: string) => {
            let cols: string[] = row.split("\t");
            let rowData: any = {};
            element.nodes.forEach((f: any, idx: number) => {
                rowData[f.id] = { value: cols[idx], valid: undefined, type: f.type }
            });
            mustUpdate = true;
            records.push(rowData);
        });
        if (mustUpdate) {
            checkAndSetPayload(records)
        }

    }
    const importFile = async () => {
        setLoading(true);
        const result = await DocumentPicker.getDocumentAsync({ type: "text/*" });
        if (result.type == "success") {
            let fileUri = result.uri;
            if (fileUri.substring(0, 5) == "data:") {
                //base64
                let res = await fetch(fileUri);
                let blob = await res.blob();
                let reader = new FileReader();
                await reader.readAsText(blob, 'utf8');
                reader.onload = function () {
                    processCSV(reader.result)
                    setLoading(false);
                }
            } else {
                let content = await FileSystem.readAsStringAsync(fileUri)
                processCSV(content);
                setLoading(false);
            }
        } else {
            setLoading(false);
        }
    }

    const getInfo = async () => {
        setLoading(true);
        let dynamicParams = await createDynamicParms(element, itemsStore.getPayloadElement, props.parentId);
        let data = await integrationsStore.getIntegration(element.integration.id, dynamicParams);
        if (Array.isArray(data)) {
            let records: any[] = [];
            data.forEach((cols) => {
                let rowData: any = {};
                element.nodes.forEach((f: any, idx: number) => {
                    rowData[f.id] = { value: null, valid: undefined, type: f.type }
                });
                for (let key in element.integration.populateValues) {
                    var f = element.nodes.find((o) => {
                        return o.id == element.integration.populateValues[key]
                    });
                    if (f) {
                        rowData[f.id] = { value: cols[key] ? cols[key] : null, valid: undefined, type: f.type }
                    }
                };
                records.push(rowData);
            });
            checkAndSetPayload(records);
        }
        setLoading(false);
    }

    const isValidRecord = (record: any = undefined) => {
        console.log(element.type, "isValidRecord");
        try {
            if (record == undefined)
                record = itemsStore.getPayloadElement(element.id, props.parentId).activeRecord.record;
            let isValid = Object.keys(record).reduce((prev: Boolean, fieldId: any) => {
                if (!record[fieldId].inactiveGroup)
                    return prev && record[fieldId].valid
                return prev;
            }, true);

            return isValid;
        } catch (e) {
            console.error("SimpleList", "checkCondition", e)
            return true;
        }
    }

    const valueToDisplay = (type, value) => {
        try {
            if (value == undefined)
                return "";
            switch (type) {
                case "select":
                    value = getDisplayAndValue(value.toString()).display;
                    break;
                case "geo":
                    return value ? `${value.coords?.latitude},${value.coords?.longitude}` : ' ';
                case "checkbox":
                    return value ? "SI" : "NO";
                case "multiple":
                    if (!Array.isArray(value))
                        value = [value];
                    value = value.map(value2 => getDisplayAndValue(value2.toString()).display).join();
                    break;
                case "timestamp":
                    value = new Date(value).getYYYYMMddHHmmss();
                    break;
                case "date":
                    value = new Date(value).getYYYYMMdd();
                    break;
                case "time":
                    value = new Date(value).getHHmm();
                    break;
            }
        } catch (e) {
            console.log(e);
        }
        return JSON.stringify(value);
    }

    const openMedia = (uri) => {
        if (uri)
            OpenFile?.openDoc(
                {
                    url: uri,
                    fileName: element.id || element.label,
                    cache: false,
                    // @ts-ignore
                    fileType: uri.substring(uri.lastIndexOf(".") + 1)
                },
                console.log
            );
    }

    const viewNodesList = (nodes: any, record: any, idx: number, elementId: string) => {
        return nodes?.map((elChild: any) => {
            const fieldId = elChild.id;
            const label = elChild.name || elChild.label;
            if (elChild.templateData?.hidden)
                return <></>;
            if (elChild.type == 'group') {
                return viewNodesList(elChild.nodes, record, idx, elChild.id);
            }
            if (record[fieldId]?.value && record[fieldId].value?.toString() != "") {
                let value = <></>;
                if (record[fieldId].type == "photo" && Array.isArray(record[fieldId].value)) {
                    value = record[fieldId].value?.map((photo: { uri: string }, idx: number) => {
                        return <Pressable onPress={() => { openMedia(photo.uri) }}><Image alt={`photo${fieldId}-${idx}`} key={`photo${fieldId}-${idx}`} source={{ uri: `${photo.uri}?refresh=${new Date().getTime()}` }} style={{ resizeMode: "contain", backgroundColor: "#000", marginTop: 2 }} width={statusStore.windowDimensions.width} height={statusStore.windowDimensions.height / 5} /></Pressable>
                    });
                    return (<VStack py={1} alignContent={"center"} key={`record-${elementId}-${idx}-${fieldId}`}>
                        <Text style={{ fontWeight: "bold" }}>{label} : </Text>
                        {value}
                    </VStack>)
                } else if (record[fieldId].type == "sign" && record[fieldId].value) {
                    return (<VStack py={1} alignContent={"center"} key={`record-${elementId}-${idx}-${fieldId}`}>
                        <Text style={{ fontWeight: "bold" }}>{label} : </Text>
                        <Image alt={`sign${fieldId}-${idx}`} key={`photo${fieldId}-${idx}`} source={{ uri: `${record[fieldId].value}?refresh=${new Date().getTime()}` }} style={{ resizeMode: "contain", backgroundColor: "#fff", marginTop: 2 }} width={statusStore.windowDimensions.width} height={statusStore.windowDimensions.height / 6} />
                    </VStack>)
                } else if (record[fieldId].type == "docs" && Array.isArray(record[fieldId].value)) {
                    value = record[fieldId].value?.map((mediaName: { uri: string }, idx: number) => {
                        return <HStack><Pressable onPress={() => { openMedia(mediaName.uri) }}
                            style={{ minHeight: 50, flex: 1, flexDirection: "row", alignItems: "center", }}>
                            <Icon mx="2" as={MaterialCommunityIcons} name="file-eye-outline" size="md" /><Text>{(mediaName.uri.substring(mediaName.uri.lastIndexOf("docs")))}</Text>
                        </Pressable></HStack>
                    });
                    return (<VStack py={1} alignContent={"center"} key={`record-${elementId}-${idx}-${fieldId}`}>
                        <Text style={{ fontWeight: "bold" }}>{label} : </Text>
                        {value}
                    </VStack>);
                } else
                    value = <Text style={{ flexWrap: "wrap" }}>{valueToDisplay(record[fieldId].type, record[fieldId].value)}</Text>

                return (<HStack py={1} key={`record-${elementId}-${idx}-${fieldId}`}>
                    <Text style={{ fontWeight: "bold" }}>{label} : </Text>
                    {value}
                </HStack>)
            }


        });
    }

    const recordView = (record: any, idx: number) => {
        console.log("recordView", idx, element.id)
        if (props.forReview) {
            return <VStack key={`SimpleList${element.id}-Row${idx}`} borderBottomWidth={1} borderBottomColor={"$light200"} px="2">
                <HStack alignItems={"center"} paddingTop={5}>
                    <Icon mx="1" size="xs" display="flex" name="ios-caret-forward-outline" as={Ionicons} color={"success.700"} />
                    <Text width={"full"} bold>{`${element.templateData.hideItemNumber == true ? '' : "#".concat((idx + 1).toString(), ": ")}${getItemTitle(element.nodes, record)} `} </Text>
                </HStack>
                <VStack px="5">
                    {viewNodesList(element.nodes, record, idx, element.id)}
                </VStack>

            </VStack>

        }
        let isValid = isValidRecord(record);
        if (element.templateData.showInline) {
            return <HStack key={`SimpleList${element.id}-Row${idx}`}
                width={statusStore.windowDimensions.width - 20} alignItems="center"
                marginLeft={-10}
                borderWidth={2} borderColor={idx % 2 == 0 ? "$light200" : "$primary100"}>
                {element.templateData.hideItemNumber == true ? <></> :
                    <HStack height={"$full"} backgroundColor={idx % 2 == 0 ? "$light200" : "$primary100"} justifyContent="center" alignItems="center">
                        <Text flexWrap={"wrap"} textBreakStrategy="simple">{`#${idx + 1} `}</Text>
                    </HStack>
                }
                <VStack key={`SimpleList${element.id}-Row${idx}`} flexWrap="wrap" ml={0} width={element.templateData?.lockDelete ? "95%" : "80%"} >
                    <FieldsList scroll={true} key={`FieldsList${element.id}-Row${idx}-${(new Date()).getTime()}`} itemsStore={itemsStore}
                        fields={element.nodes}
                        forReview={props.forReview}
                        recordIdx={idx}
                        parentId={element.id}
                    />
                </VStack>
                <Spacer />
                {element.templateData?.lockDelete ? <></> :
                    <IconButton m="0" p="0" colorScheme="danger" onPress={() => removeRecord(idx)} icon={<Icon as={Ionicons} name="trash" size="lg" />} />
                }
            </HStack>
        }
        
        const titleItem = getItemTitle(element.nodes, record, element.templateData.titleAsColumns);
        const widthRow=(statusStore.windowDimensions.width - 80);
        const widthCol = (statusStore.windowDimensions.width - 80) / (element.templateData.titleAsColumns ? titleItem.length : 1)
        return <VStack key={`SimpleList${element.id}-Row${idx}`}>
            <HStack alignItems={"center"} borderBottomWidth={1} borderBottomColor={"$light200"} px="2" py="2" >
                <Pressable _pressed={{ bg: "secondary.600:alpha.20" }} _hover={{ bg: "secondary.600:alpha.20" }} onPress={() => openRecord(idx)} width="90%">
                    <HStack alignItems={"center"} py={2} maxWidth={widthRow} maxH="200" overflow="hidden">
                        <Icon mx="1" size="lg" display={!isValid ? "flex" : "none"} name="information-circle" as={Ionicons} color={"danger.400"} />
                        <Icon mx="1" size="xs" display={isValid ? "flex" : "none"} name="ios-caret-forward-outline" as={Ionicons} color={"success.700"} />
                        {element.templateData.titleAsColumns!= true ?
                            <Text flexWrap={"wrap"} textBreakStrategy="simple">{`${element.templateData.hideItemNumber == true ? '' : "#".concat((idx + 1).toString(), ": ")}${titleItem} `.toUpperCase()} </Text>
                            :
                            <HStack>
                                {titleItem.map((t,idx) => <View key={"colValue".concat(idx)} width={widthCol} justifyContent={"center"} alignItems={"center"}><Text  flexWrap={"wrap"} textBreakStrategy="simple">{t}</Text></View>)}
                            </HStack>
                        }
                    </HStack>
                </Pressable>
                <Spacer />
                <IconButton display={element.templateData?.lockDelete ? "none" : "flex"} colorScheme="danger" onPress={() => removeRecord(idx)} icon={<Icon as={Ionicons} name="trash" size="lg" />} />
            </HStack>
        </VStack>
    }

    const loadHeaders = (opts: [string]) => {
        console.log("loadHeaders");
        let records: any[] = [];
        opts.forEach((opt: string) => {
            let rowData: any = {};
            element.nodes.forEach((f: any, idx: number) => {
                let value = idx == 0 ? opt : null;
                let nodeValid = false;//itemsStore.getErrorElement(f, value);
                rowData[f.id] = { value, valid: nodeValid, type: f.type }
                if (f.type == "select" && f.templateData.options.find(o => o.split("|")[0].includes("*"))) {
                    rowData[f.id].value = f.templateData.options.find(o => o.split("|")[0].includes("*"));
                    rowData[f.id].valid = true;
                }
            });
            rowData[element.nodes[0].id].value = opt;
            records.push(rowData);
        })
        console.log("loadHeaders", records);
        checkAndSetPayload(records)
    }
    function headerView(header){
        
        const widthRow=(statusStore.windowDimensions.width - 80);
        const widthCol = (statusStore.windowDimensions.width - 80) / (element.templateData.titleAsColumns ? header.length : 1)
        return <VStack key={`SimpleList${element.id}-Header`} py={5} bgColor="$light200">
            <HStack alignItems={"center"} borderBottomWidth={1} borderBottomColor={"$light200"} px="2" py="2" >
                    <HStack alignItems={"center"} py={2} maxWidth={widthRow} maxH="200" overflow="hidden" >
                                {header.map((t,idx) => <View key={"colValue".concat(idx)} width={widthCol} justifyContent={"center"} alignItems={"center"}><Text fontWeight={"bold"} flexWrap={"wrap"} textBreakStrategy="simple">{t}</Text></View>)}

                        
                    </HStack>
                
                  </HStack>
        </VStack>

    }

    function reloadSetListRecords() {
        console.log("Change Payload SiMPLE LIST ", element.id);
        if ((payload.value || []).length > 0) {
            let title:any=[];
            if(element.templateData.titleAsColumns){
                title=[headerView(getItemHeader(element.nodes,true))]
            }
            setListRecords(title.concat(
                payload.value.map((record, idx) => {
                    return recordView(record, idx);
                }))
            )
        } else
            setListRecords(<View></View>)
    }
    const getInfoDebouneced = useDebouncedCallback(getInfo, 10);
    const reloadSetListRecordsDebouneced = useDebouncedCallback(reloadSetListRecords, 10);

    React.useEffect(() => {
        const onResizeWindow = Dimensions.addEventListener('change', reloadSetListRecordsDebouneced);
        return () => onResizeWindow.remove();
    }, [])

    React.useEffect(() => {
        reloadSetListRecords();

    }, [payload.value, props.forReview])


    React.useEffect(() => {
        if ((payload.value || []).length > 0)
            if (element.templateData.showInline) {
                let value = itemsStore.getPayloadElement(element.id, props.parentId, props.recordIdx).value;
                checkAndSetPayload(value);
            }
    }, [props.forReview])

    React.useEffect(() => {
        console.log(element.type, "payload.useEffect");
        itemsStore.updPayloadElement(element.id, payload, props.parentId);
        if ((payload.value || []).length == 0)
            if (element.isDinamic != true && element.templateData.options?.length > 0) {
                loadHeaders(element.templateData.options);
            } else {
                if (element.validation?.required && element.integration?.id) {
                    getInfo();
                }
            }
        setModalVisible(payload.activeRecord && payload.activeRecord.idx != undefined);
    }, [payload]);

    React.useEffect(() => {
        setErrorList(itemsStore.getErrorElement(element, payload.value || []).errorList);
        if (modalVisible == true) {
            if (payload.activeRecord?.idx != undefined)
                setIsValidActiveRecord(isValidRecord());
            let _disposerOnPatch = onPatch(itemsStore, patch => {
                console.log(element.type, "onPatch")
                if (patch.path.includes(element.id.concat("/activeRecord/record/")))
                    setIsValidActiveRecord(isValidRecord());
            });
            return () => {
                _disposerOnPatch();
                _disposerOnPatch = undefined;
            }
        } else {
            if (element.isDinamic == true || element.integration) {
                let _disposerOnPatch = onPatch(itemsStore, patch => {
                    if (element.isDinamic == true && !patch.path.includes(element.id.concat("/value/"))) {
                        console.log("modalHidden", patch.path);
                        if (element.templateData.dynamicOptions?.length > 0) {
                            const opts: [string] = conditionIsGo(element.templateData.dynamicOptions, itemsStore.itemActive.toJSON(), props.parentId);
                            if (Array.isArray(opts) && opts.length > 0) loadHeaders(opts);
                        }
                    }

                    if (element.integration && element.integration.userMode == false && element.integration.dynamicParams) {
                        Object.keys(element.integration.dynamicParams).forEach((v: any) => {
                            let myDependency = element.integration?.dynamicParams[v];
                            if (myDependency != element.id && patch.path.includes(myDependency))
                                getInfoDebouneced();
                        });
                    }
                });
                return () => {
                    _disposerOnPatch();
                    _disposerOnPatch = undefined;
                }
            }
        }
        return () => { }
    }, [modalVisible])

    const actionButtons = []
    if (loading || modalVisible)
        actionButtons.push(<Spinner key="lsSpinnerLoading" size="sm" />)
    if (!props.forReview && !loading /* && !modalVisible*/) {
        if (element.templateData?.lockAdd != true)
            actionButtons.push(
                <IconButton key="btnAdd" size="lg" onPress={addRecord} icon={<Icon as={Ionicons} name="add-circle-outline" size="lg" borderRadius="md" backgroundColor="white" />} />
            );
        if (element.templateData?.allowImportCSV == true)
            actionButtons.push(
                <IconButton key="btnImportFile" size="lg" onPress={importFile} icon={<Icon as={FontAwesome5} name="file-csv" size="lg" borderRadius="md" backgroundColor="white" />} />
            );
        if (element.integration?.id)
            actionButtons.push(
                <IconButton key="btnImportIntegration" size="lg" onPress={getInfo} icon={<Icon as={FontAwesome5} name="download" size="lg" borderRadius="md" backgroundColor="white" />} />
            );
    }
    return <ElementBase boxStyle={{ padding: 0, paddingRight: 2, }}
        errors={errorList}
        leftButton={(<Icon size="lg" name="list" as={MaterialIcons} />)}
        rigthButton={actionButtons}
        element={element}
        forReview={props.forReview}>
        {modalVisible && payload.activeRecord?.record ?
            <ModalFull
                visible={modalVisible}
                title={payload.activeRecord.idx == -1 ? "Nuevo Registro" : "Registro #".concat((payload.activeRecord.idx + 1).toString())}
                closeButton_onPress={cancelRecord}
                footer={<>
                    <Button colorScheme={isValidActiveRecord ? "blue" : "gray"}
                        disabled={!isValidActiveRecord} width={"full"} p="2"
                        borderRadius={0}
                        onPress={saveRecord}
                        leftIcon={<Icon as={Ionicons} name="checkmark" size="lg" />}>
                        Aceptar
                    </Button>
                </>
                }><View width="100%" height="100%" bgColor={"white"} alignSelf={"center"} borderWidth={1}>
                    <ScrollView>{props.children}</ScrollView></View>
            </ModalFull>
            : ((Array.isArray(payload.value) && payload.value.length > 0) ?
                <VStack shadow={"1"} borderWidth={0} borderColor={"#eee"} pl="2" marginBottom={5} backgroundColor={"white"}>
                    {listRecords}
                    {payload.value.length > 6 && element.templateData?.lockAdd != true ?
                        <HStack alignItems="center" key="addRecordButton_bottom" >
                            <Button colorScheme={"primary"} w="100%" leftIcon={<Icon as={Ionicons} name="add" size="xl" />}
                                onPress={addRecord}>
                                AGREGAR REGISTRO
                            </Button>
                        </HStack>
                        :
                        <></>}
                </VStack>
                : <></>)
        }



    </ElementBase>
}