
import { Box, HStack, Icon, VStack, Text, } from '@gluestack-ui/themed';
import React, { FunctionComponent } from 'react';
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import { useStores } from '../../models';
import { PAYLOAD_TYPE } from '../../voolks/types';
import { onAction } from 'mobx-state-tree';
import { useDebouncedCallback } from 'use-debounce';
import { useNavigation } from '@react-navigation/native';
import { customApp } from '../../config';
import Spacer from '../base/Spacer';
import { Linking, Image as ImageRN, Pressable, View } from 'react-native';

export interface IElementBaseProps {
    errors?: string[],
    element: any,
    rigthButton?: any,
    leftButton?: any,
    forReview?: boolean,
    boxStyle?: any,
    parentId?: string,
    recordIdx?: number,
    hideTitleBar?: boolean,
    borderBottomWidth?: number,
}

export const getIconHasError = (hasError: boolean) => {
    return (
        hasError ?
            <Icon as={Ionicons} name="close-circle" size="lg" color="$danger500" />
            :
            <></>
    )
}


export const initPayloadValid = (element, payload, setPayload, getErrorElement) => {
    console.log(element.name, element.type, element.id, payload.inactiveGroup)
    if (payload.valid == null || payload.inactiveGroup) {
        let errorElement = getErrorElement(element, payload.value);
        setPayload({ ...payload, valid: errorElement.valid, inactiveGroup: false });
    }
}

export const useInitializeElement = (props, withUpdPayloadElement = true,
    parseValueFromPopulate = ((value, element, itemStore) => value)) => {
    const element = props.element;
    //console.log(props.element.type, props.element.id, props.parentId, "initializeElement")
    const { itemsStore, statusStore, integrationsStore, authenticationStore } = useStores();

    const [payload, setPayload] =
        React.useState<PAYLOAD_TYPE>(itemsStore.getPayloadElement(element.id, props.parentId, props.recordIdx));
    const [errorList, setErrorList] = React.useState<string[]>(itemsStore.getErrorElement(element, payload?.value).errorList)
    const [modalVisible, setModalVisible] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const firstRun = React.useRef(true)
    const navigation = useNavigation();
    props.forReview = props.forReview || payload.readOnly;
    const updDebounced = useDebouncedCallback(() => {
        try {
            itemsStore.updPayloadElement(element.id, payload, props.parentId, props.recordIdx, element.templateData?.closeRecord);
            if (element.templateData?.closeRecord) {
                const ItemsListScreenName = customApp.isPTW ? "PTWHome" : "ItemsList";
                // @ts-ignore
                navigation.navigate(ItemsListScreenName, navigation.getState().routes.find(k => k.name == ItemsListScreenName).params);
            }
        } catch (e) {
            console.log(element.id, element.name, e);
        }
    }, ["text", "number"].includes(element.type) ? 5 : 0);

    React.useEffect(() => {
        initPayloadValid(element, payload, setPayload, itemsStore.getErrorElement);
    }, [])
    if (withUpdPayloadElement)
        React.useEffect(() => {
            if (firstRun.current || element.type == "group")
                firstRun.current = false;
            else {
                updDebounced()
            }

        }, [payload]);

    React.useEffect(() => {
        let _disposerOnAction = onAction(statusStore, async call => {
            if (call.name == "reloadPayloadElementFromPopulate" && Object.values(call.args[1]).includes(element.id)) {
                let value = itemsStore.getPayloadElement(element.id, props.parentId, props.recordIdx).value;
                value = await parseValueFromPopulate(value, element, itemsStore);
                if (element.type == "simplelist") {
                    value = JSON.parse(JSON.stringify(value));
                    function walk(nodes, record) {
                        nodes.forEach((f: any) => {
                            let nodeValid = itemsStore.getErrorElement(f, record[f.id]?.value);
                            if (record[f.id] == undefined)
                                record[f.id] = { value: record[f.id]?.value, valid: (f.isArchived || nodeValid?.valid), type: f.type, templateData: { useAsTitle: f.templateData?.useAsTitle } }
                            if (Array.isArray(f.nodes)) walk(f.nodes, record);
                        });
                    }
                    value.forEach(record => walk(element.nodes, record));
                }
                checkAndSetPayload(value);
            }
        })
        return () => {
            _disposerOnAction();
        }
    }, []);

    const checkAndSetPayload = (payloadValue, activeRecord = undefined) => {
        if (payload.readOnly)
            return null;
        let errorElement = itemsStore.getErrorElement(element, payloadValue);
        setPayload({ ...payload, value: payloadValue, valid: errorElement.valid, activeRecord: (activeRecord || payload.activeRecord) });
        setErrorList(errorElement.errorList);


        return errorElement;
    }

    return {
        itemsStore, statusStore, integrationsStore, authenticationStore,
        element,
        payload, setPayload, checkAndSetPayload,
        errorList, setErrorList,
        modalVisible, setModalVisible,
        loading, setLoading,
    }
}


export const initializeElement = useInitializeElement;

export const ElementBase: FunctionComponent<IElementBaseProps> = ({ ...props }) => {
    const element = props.element;
    const link: string = element.templateData?.href;
    if (element.templateData?.hidden_label)
        props.hideTitleBar = true;
    let errors: any[] = []
    if (props.errors?.length > 0) {
        errors = props.errors.map((error, idx) => {
            return (<Box key={`error${idx}`} ><Text fontWeight="bold" color="$red500" fontSize={14} paddingHorizontal={10} paddingVertical={3}>{error}</Text></Box>)
        })
    }


    return element.type == "group" ? <>{props.children}</> : (
        <Box
            display={(element.templateData?.hidden ? "none" : "flex")}
            borderBottomWidth={props.borderBottomWidth == undefined ? 1 : props.borderBottomWidth}
            borderBottomColor="$warmGray300"
            my={2} /* minWidth={275}  */ width={"$full"}
            mx={1}
            paddingTop={10}
            paddingHorizontal={"$4"}
            backgroundColor={element.templateData?.backgroundColor || "#fff"}
            style={props.boxStyle}
        >{props.hideTitleBar ? <></> : <HStack alignItems={"center"}>
            <Box key="leftButtonsSection" style={{ maxWidth: 120, margin: 0, flexDirection: "row-reverse" }} >{props.leftButton}</Box>
            <Box key="NameSection" flexShrink={1} flexDirection='row'>
                <Text bold color={element.templateData?.textColor || "#000"}>{element.name ?? element.label}</Text>
                {link ?
                    <Pressable onPress={() => Linking.openURL(link)}>
                        <Icon name="launch" as={MaterialIcons} size={"md"} color={"$closed400"} />
                    </Pressable>
                    : <></>}
            </Box>
            <Spacer></Spacer>
            <Box key="RightButtonSection" style={{ maxWidth: 120, margin: 0, flexDirection: "row-reverse" }} >{getIconHasError(errors.length > 0)}{props.rigthButton}</Box>
        </HStack>
            }
            {props.children}
            {props.forReview != true && errors.length > 0 ? <VStack borderTopWidth={1} marginVertical={5} paddingTop={"$2"} paddingLeft={"$2.5"} paddingBottom={"$4"} borderColor="$danger500">{errors}</VStack> : <></>}
        </Box>
    );
};