import { Alert, Platform } from 'react-native'

export const urlFileToBase64 = async (urlFile) =>{
    const data = await fetch(urlFile);
        const blob = await data.blob();
        const reader = new FileReader();
        
        return await (new Promise((resolve,reject)=>{
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                resolve(reader.result);
            };
        }))    
}

export const calcularVelocidadConexion = async (setSpeedTest:any,statusStore:any) => {
    const inicioTiempo = new Date().getTime();
    const archivo = "https://field.voolks.com/images/speedTest.jpg?inicio=".concat(inicioTiempo.toString()); // URL de un archivo de prueba

    try {
        statusStore.setStatus("pending");
        statusStore.addLoadingInfo({ title: "Calculando tiempo de descarga del servidor", spinner: true })
        const response = await fetch(archivo);
        await response.blob();
        const finTiempo = new Date().getTime();
        const tiempoTotal = finTiempo - inicioTiempo;
        const velocidadBytes = response.headers.get('Content-Length') / (tiempoTotal / 1000); // Calcular la velocidad en bytes por segundo
        const velocidadMbps = (velocidadBytes * 8 / 1000000).toFixed(2); // Convertir a Mbps
        setSpeedTest(velocidadMbps);
        console.log("La velocidad de conexión es: " + velocidadMbps + " Mbps");
        statusStore.clearLoadingInfo();
        statusStore.setStatus("done");
    } catch (error) {
        console.error("Error al cargar la imagen:", error);
    }
};

export const convertImageToBase64 = async (imgUrl, callback) => {
    if (Platform.OS == "web") {
        const image = new Image();
        image.crossOrigin = 'anonymous';
        image.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.height = image.naturalHeight;
            canvas.width = image.naturalWidth;
            ctx.drawImage(image, 0, 0);
            const dataUrl = canvas.toDataURL();
            callback && callback(dataUrl)
        }
        image.src = imgUrl;
    } else {       
        const data = await fetch(imgUrl);
        const blob = await data.blob();
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const base64data = reader.result;            
            callback && callback(base64data)
        };
    }
}


export const shouldTaskAppearToday = (task: any, selectedDate: Date) => {
    enum weekDays { 'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday' };
    let checkDate: Date = new Date(selectedDate);// checkDate.setHours(23, 59, 59);
    let startDate: Date = new Date(task.startDate);    
    let endRepeat = task.repeatData.repeatEnds ? new Date(task.repeatData.repeatTill) : null;

    if (endRepeat) {
        endRepeat.setHours(23, 59, 59);
    }

    if (!task.repeat) {
        return true;
    }

    let daysDiff = (new Date(checkDate.toLocaleDateString("af")).getTime() - new Date(startDate.toLocaleDateString("af")).getTime() )/ (1000 * 3600 * 24);  //Math.ceil((checkDate.valueOf() - startDate.valueOf()) / (1000 * 3600 * 24))
    if (daysDiff < 0) {
        
        return false;
    }

    //if repeat ends, we check if we should show it...
    if (endRepeat && (endRepeat.valueOf() - checkDate.valueOf()) < 0) {
        return false;
    }

    if (task.repeatData.repeatType === 'daily') {
        let diff = daysDiff % task.repeatData.repeatEvery;
        //if the diff between days passed and repeat every is exact, we know we have to show the task.
        if (diff==0) {
            return true;
        }
        return false;
    }

    if (task.repeatData.repeatType === 'weekly') {
        //we check to see if selectedDate week day is true or false...
        if (task.repeatData.repeatWeekDays[weekDays[checkDate.getDay()]]) {
            return true;
        }
        return false;
    }
}



const alertPolyfill = (title, description, options, extra) => {
    const result = window.confirm([title, description].filter(Boolean).join('\n'))

    if (result) {
        const confirmOption = options.find(({ style }) => style !== 'cancel')
        confirmOption && confirmOption.onPress()
    } else {
        const cancelOption = options.find(({ style }) => style === 'cancel')
        cancelOption && cancelOption.onPress()
    }
}

export const base64Encode = (input:string) =>{
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
    let str = input;
    let output = '';

    for (let block = 0, charCode, i = 0, map = chars;
        str.charAt(i | 0) || (map = '=', i % 1);
        output += map.charAt(63 & (block >> (8 - (i % 1) * 8)))
    ) {
        charCode = str.charCodeAt(i += 3 / 4);
        if (charCode > 0xFF) {
            throw new Error("'base64Encode' failed: The string to be encoded contains characters outside of the Latin1 range.");
        }
        block = (block << 8) | charCode;
    }

    return output;
}

export const alert = Platform.OS === 'web' ? alertPolyfill : Alert.alert