import { Input,  } from 'native-base';
import React, { FunctionComponent } from 'react';
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import { ElementBase, IElementBaseProps, ModalFull, initializeElement } from '..';
import { RNPicker } from "../base/RNPicker";

import { getDataFromIntegration, getDisplayAndValue } from '../../utils/itemCommonFunctions';
import { TextInput } from 'react-native-gesture-handler';
import { Platform } from 'react-native';
import { onPatch } from 'mobx-state-tree';
import { conditionIsGo } from '../../utils/conditionIsGo';
import { useDebouncedCallback } from 'use-debounce';

import { View, ScrollView, Button, ButtonIcon, Icon as Icon2, Pressable, ButtonText, CheckIcon, HStack, VStack, Spinner, Text, } from "@gluestack-ui/themed"
import Spacer from '../base/Spacer';

export const MultipleElement: FunctionComponent<IElementBaseProps> = ({ ...props }) => {
    const fcName = "MultipleElement";
    //console.log(fcName, "render")

    const {
        itemsStore, integrationsStore, element, statusStore, checkAndSetPayload,
        payload, setPayload,
        errorList,
        modalVisible, setModalVisible
    } = initializeElement(props);

    const asSelect = (element.type == 'select' || element.type == 'sendemail1')
    const [options, setOptions] = React.useState<string[]>((element.integration?.id == undefined && element.isDinamic == false) ? element.templateData.options : []);

    const [loadingOptions, setLoadingOptions] = React.useState<boolean>(false);
    const [filterActived, setFilterActived] = React.useState<string>("");

    const handlePressed = (val: string | string[]) => {
        if (props.forReview)
            return;
        if (!element.templateData.onlyOne && !asSelect) {
            let pay = [];
            if (payload.value && Array.isArray(payload.value))
                pay = payload.value.slice();
            if (pay.includes(val))
                pay.splice(pay.indexOf(val), 1);
            else if (val.toString().substring(0, 6) == "OTHER|" && pay.find(v => v.substr(0, 6) == val.toString().substring(0, 6)))
                pay.splice(pay.findIndex(v => v.substr(0, 6) == val.toString().substring(0, 6)), 1);
            else
                pay.push(val);
            val = pay;
        } else if (element.templateData.optionsAsButtons && payload.value == val)
            val = undefined;
        checkAndSetPayload(val);
    }

    const isSelectedOther = (): boolean => {
        if (payload.value == undefined)
            return false;
        if (Array.isArray(payload.value))
            return payload.value.reduce((hasOther, itemSelected) => hasOther || itemSelected.toString().substring(0, 6) == "OTHER|", false);
        console.log("payload.value", payload.value)
        return payload.value.toString().substring(0, 6) == "OTHER|"
    };

    const handleTextOther = (val: string) => {
        val = val || "";
        let value;
        if (Array.isArray(payload.value))
            value = payload.value.map((itemSelected) => {
                //MG.console.log(itemSelected, itemSelected.substring(0, 6).toUpperCase() == "OTHER|")
                return itemSelected.substring(0, 6).toUpperCase() == "OTHER|" ? "OTHER|##".concat(val || "").concat("##") : itemSelected
            });
        else
            value = payload.value.substring(0, 6).toUpperCase() == "OTHER|" ? "OTHER|##".concat(val.concat("##"))
                : payload.value;
        setPayload({ ...payload, value });
    }

    const textOtherDisplay = () => {
        let val = Array.isArray(payload.value) ? payload.value : [payload.value];
        return val.reduce((text, itemSelected, idx) => {
            if (text != "")
                return text;
            if (itemSelected.substring(0, 8).toUpperCase() == "OTHER|##")
                return itemSelected.substring(8, itemSelected.length - 2);
            if (itemSelected.substring(0, 6).toUpperCase() == "OTHER|")
                text = "";
            return text;
        }, "");

    }

    const displayPayload = (): string => {
        if (payload.value == undefined)
            return "";
        if (Array.isArray(payload.value))
            return payload.value.reduce((valueString, itemSelected) => valueString.concat(getDisplayAndValue(itemSelected).display).concat(", "), "");
        return getDisplayAndValue(payload.value.toString()).display;

    };

    const setDefaultValue = () => {
        let _data = options;
        if (!payload.value || (Array.isArray(payload.value) && payload.value.length == 0))
            _data.map(item => {
                if (getDisplayAndValue(item).default)
                    handlePressed(item);
            });
    }

    const putOptions = (data: string[]) => {
        console.log(fcName, "putOptions")
        setOptions(element.templateData.sort ? [].concat(data).sort() : data);
    }

    const reloadOptions = async () => {
        console.log(fcName, "reloadOptions", "IN")
        let payload = itemsStore.getPayloadElement(element.id, props.parentId, props.recordIdx);
        if (props.forReview || loadingOptions)
            return;
        const { id, templateData, integration, name, label } = element;

        let opts: any = [];
        if (integration?.id) {
            setLoadingOptions(true);
            opts = await getDataFromIntegration(element, itemsStore.getPayloadElement, props.parentId, props.recordIdx,
                integrationsStore.getIntegration);
            setLoadingOptions(false);
        } else if (element.isDinamic && templateData.dynamicOptions?.length > 0) {
            opts = conditionIsGo(element.templateData.dynamicOptions, itemsStore.itemActive.toJSON(), props.parentId, props.recordIdx);
        } else
            opts = element.templateData.options;
        if (!Array.isArray(opts))
            opts = [];
        putOptions(opts);

        if (Array.isArray(payload.value)) {
            let mustClean = false;
            payload.value.forEach(value => {
                if (value && !(opts.includes(value) || value.includes("OTHER|##")))
                    mustClean = true;
            })
            if (mustClean)
                setPayload({ ...payload, value: null });
        } else {
            if (payload.value && !(opts.includes(payload.value) || payload.value.toString().includes("OTHER|##")))
                setPayload({ ...payload, value: null });
        }
        console.log(fcName, "reloadOptions", "FIN")
    }

    const reloadOptionsDebouneced = useDebouncedCallback(reloadOptions, 10);

    React.useEffect(() => {
        try {
            //console.log(fcName, "UseEffect[]");
            options.length == 0 && !element.integration?.userMode && reloadOptions();
            let _disposerOnPatch = onPatch(itemsStore, patch => {
                let mustReloadOptions = false;
                if (element.integration && element.integration.userMode != true && element.integration.dynamicParams) {
                    Object.keys(element.integration.dynamicParams).forEach((v: any) => {
                        let myDependency = element.integration?.dynamicParams[v];
                        if (myDependency != element.id && patch.path.includes(myDependency))
                            mustReloadOptions = true;
                    });
                }
                if (element.isDinamic && element.templateData.dynamicOptions) {
                    element.templateData.dynamicOptions
                        .forEach(dynamicOption => {
                            if (dynamicOption.conditions)
                            dynamicOption.conditions.forEach(condition => {
                                if (JSON.stringify(condition).includes(patch.path.split("/").slice(-2, -1).toString()))
                                    mustReloadOptions = true;
                            });
                            if (dynamicOption.filterOptions)
                                dynamicOption.filterOptions.forEach(filterOptions => {
                                    if (JSON.stringify(filterOptions).includes(patch.path.split("/").slice(-2, -1).toString()))
                                        mustReloadOptions = true;
                                });
                        });
                }
                if (mustReloadOptions) {
                    console.log("MultipleElement", "onPatch", "MustReloadOptions=true")
                    reloadOptionsDebouneced();
                    setFilterActived("");
                }
            });

            return () => {
                _disposerOnPatch();
                _disposerOnPatch = undefined;
            }
        } catch (e) { }
    }, [undefined, props.forReview]);



    const showSelect = () => {
        let _options = Array.isArray(options) ? options : [];
        return <RNPicker testID="rnpicker-MultipleEl"
            data={(_options || [])?.map((item, idx) => ({ id: idx.toString(), name: getDisplayAndValue(item).display, item }))}
            defaultValue={true}
            showSearchBar={(_options || []).length > 10} searchBarPlaceHolder={"Buscar....."}
            selectedText={payload.value ? getDisplayAndValue(payload.value.toString()).display : undefined}
            selectedValue={(index: number, item: { id: string, name: string, item: string }) => handlePressed(item.item)}
        />
    }

    const showButtonsChecks = () => {
        const isOptionSelected = (option): boolean => {
            if (payload?.value == undefined)
                return false;
            if (Array.isArray(payload.value))
                return payload.value.includes(option) || (option.substring(0, 6) == "OTHER|" && payload.value.toString().includes("OTHER|"));
            return payload.value == option || (option.substring(0, 6) == "OTHER|" && payload.value.toString().includes("OTHER|"));
        }

        const dataSource = (options || []).filter((opt) => getDisplayAndValue(opt).display.toLowerCase().includes(filterActived.toLowerCase()))
        return dataSource.map((opt, idx) => {
            if (element.templateData.optionsAsButtons) {
                  return <Pressable key={`opt${idx}`} onPress={() => handlePressed(opt)} marginVertical={4} marginHorizontal={4} style={{ maxWidth: '100%' }}>
                    <VStack borderColor="$primary400" borderWidth="$1" borderRadius={5}
                        bgColor={isOptionSelected(opt) ? "$primary400" : "$white"}
                        marginHorizontal={4}
                        paddingVertical={10} paddingHorizontal={10}
                        width="$full" alignItems='center'>
                        <Text style={{maxWidth: '95%'}} color={isOptionSelected(opt) ? "$white" : "$primary400"} >{getDisplayAndValue(opt).display.toUpperCase()}</Text>
                    </VStack></Pressable>
            } else {
                return (<Pressable key={`opt${idx}`} onPress={() => handlePressed(opt)} marginVertical={4} marginHorizontal={4} style={{ maxWidth: '100%' }}>
                    <HStack                    
                    marginHorizontal={4}
                    paddingVertical="$1" paddingHorizontal="$1"
                    width="$full" alignItems='center' paddingBottom={5}
                    borderBottomColor='$light100' borderBottomWidth="$1"
                    >
                    {isOptionSelected(opt) == true ?                        
                        <Icon2 name="checkbox-outline" as={Ionicons} size="lg" />
                        :
                        <Icon2 name="square-outline" as={Ionicons} size="lg" />
                        
                    }
                        <Text style={{ maxWidth: '95%' }} px="$2">{getDisplayAndValue(opt).display}</Text>
                </HStack>
                </Pressable>)
            }
        });
    }

    const renderNoModal = () => {
        return (asSelect && !element.templateData.optionsAsButtons ?
            showSelect() :
            (element.templateData.optionsAsButtons) ?
                <HStack flexWrap="wrap">{showButtonsChecks()}</HStack>
                : <VStack>{showButtonsChecks()}</VStack>)
    }

    const getRender = () => {
        return <ElementBase errors={errorList} element={element} forReview={props.forReview} rigthButton={btnCallIntegration}>
            {!props.forReview ?
                <>
                    {(element.templateData.noModal && options?.length < 20) || asSelect ?
                        renderNoModal()
                        :
                        <>
                            <HStack>
                                <Input isReadOnly
                                    style={{ color: (element.templateData.textColor || "#000") }}
                                    placeholder={element.templateData.placeholder || ""}
                                    value={displayPayload()}
                                    width="90%"
                                    borderWidth="0" size="md"
                                />
                                <Spacer></Spacer>
                                <Button  borderRadius={0} width={55} height={55} p="$1" bgColor={"$white"}
                                    variant="solid"
                                    key="itemsStore-openPDF"
                                    onPress={() => { setModalVisible(!modalVisible) }} 
                                >
                                    <ButtonIcon as={MaterialCommunityIcons} name="checkbox-multiple-marked" fontWeight="$extrabold" size="xl" color="$primary400" />
                                </Button>                                
                            </HStack>
                            {modalVisible && <ModalFull
                                visible={modalVisible}
                                title="Elija las opciones"
                                closeButton_onPress={() => { setModalVisible(false); setFilterActived(""); }}
                                footer={<>
                                    <Button width={"100%"} size="md" variant="solid" action="primary"
                                        borderRadius={0}
                                        onPress={() => { setModalVisible(false); setFilterActived(""); }}>
                                        <ButtonIcon size='md' as={CheckIcon} />
                                        <ButtonText>Aceptar</ButtonText>
                                    </Button>
                                </>
                                }><View width="100%" height="100%" bgColor={"white"} alignSelf={"center"} borderWidth={1}>
                                    <HStack>
                                        {options.length < 20 ? <></>
                                            :
                                            <TextInput underlineColorAndroid={"transparent"}
                                                placeholder={"Buscar..."}
                                                onChangeText={setFilterActived}
                                                returnKeyType={"done"}
                                                blurOnSubmit={true}
                                                style={{
                                                    color: "black",
                                                    padding: 5,
                                                    marginTop: Platform.OS == "ios" ? 10 : 0,
                                                    marginBottom: Platform.OS == "ios" ? 10 : 0,
                                                    alignSelf: "center",
                                                    flex: 1, borderRadius: 5, backgroundColor: "#fff",
                                                    borderColor: '#ccc',
                                                    borderWidth: 1,
                                                    height: 40,
                                                    width: statusStore.windowDimensions.width * 0.9
                                                }}
                                            />

                                        }
                                    </HStack>
                                    <ScrollView>
                                        {showButtonsChecks()}
                                    </ScrollView></View>
                            </ModalFull>
                            }
                        </>
                    }
                    {isSelectedOther() ?
                        <Input keyboardType={"default"} style={{ color: (element.templateData.textColor || "#000") }} onChangeText={handleTextOther}
                            value={textOtherDisplay() || ""}
                            placeholder={"Escriba aqui otra Opcion"}
                            size={"md"}
                        />
                        : null}
                </>
                :
                <Text>{displayPayload()}</Text>
            }
        </ElementBase>
    }

    React.useEffect(() => {
        try {
            !(payload.value == null && options.length == 0) && setDefaultValue()
        } catch (e) { }
        //setRender(getRender())
    }, [options, payload, modalVisible])

    let btnCallIntegration = loadingOptions ? <Spinner size="small" /> : <></>;
    if (!props.forReview && !loadingOptions && element.integration && element.integration.id && element.integration.userMode)
        btnCallIntegration = (<Button borderRadius={0} width={55} height={55} p="$1" bgColor={"$white"}
                    variant="solid"
            key="btnImportIntegration" size="lg" 
            onPress={reloadOptions}
                  ><ButtonIcon as={Ionicons} name="sync-circle" fontWeight="$extrabold" size="xl" color="$primary400" />
                  </Button>)
                  
  
    //   options.length == 0 && !element.integration?.userMode && reloadOptions();

    return getRender();
}